import { Input } from "@chakra-ui/input"
import { Divider, Stack } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import { ExtractAllIngredients } from "../utils/ExtractAllIngredients"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
import { Alphabet } from "../components/global/Alphabet"
import SearchIcon from "../components/assets/icons/SearchIcon"
export default function Index({ data }) {
  const rawIngredients = data.allSanityRecipes.distinct
  const [input, setInput] = useState("")

  const ingredients = ExtractAllIngredients(rawIngredients)

  const alphabet = Alphabet()
  return (
    <Layout>
      <SearchEngineOptimizaion
        title={"Indeks over ingredienser | Gastrologik"}
        description={
          "Her finder du en oversigt over de ingredienser, som jeg bruger her på Gastrologik."
        }
      />

      <div className="titleWrapper">
        <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>
        <BlockContent
          blocks={data.sanityPages._rawBody}
          projectId="2aoj8j2d"
          dataset="gastrologik"
          serializers={serializers}
          className="recipeBlockContent"
        />
      </div>
      <div className="ingredientSearch">
        <Stack width="300px" margin="auto" direction="row" mb="5">
          <Input
            className="input"
            placeholder="søg..."
            value={input}
            onChange={e => {
              setInput(e.target.value)
            }}
          />
          <Button
            onClick={() => {
              document.querySelector(".input").focus()
            }}
          >
            <SearchIcon />
          </Button>
        </Stack>
      </div>
      {input.length > 0 ? (
        <div className="ingredientsList ">
          {ingredients.map((ingredient, ingredientIndex) => {
            if (ingredient.includes(input)) {
              return (
                <div key={ingredient + ingredientIndex}>
                  {" "}
                  <Link to={"/indeks/" + ingredient + "/"}>
                    <div className="ingredientSearch">{ingredient}</div>
                  </Link>
                </div>
              )
            } else {
              return ""
            }
          })}
        </div>
      ) : (
        <section className="ingredientsList">
          {alphabet.map((letter, letterIndex) => {
            return (
              <div key={letter + letterIndex}>
                <h2> {letter}</h2>
                <ul className="letterList">
                  {ingredients.map((ingredient, ingredientIndex) => {
                    if (ingredient.startsWith(letter)) {
                      return (
                        <li key={ingredient + ingredientIndex + letterIndex}>
                          <Link to={"/indeks/" + ingredient + "/"}>
                            <div>{ingredient}</div>
                          </Link>
                        </li>
                      )
                    } else {
                      return ""
                    }
                  })}
                </ul>{" "}
                <Divider mb="50px" />
              </div>
            )
          })}
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    allSanityRecipes {
      distinct(field: { ingredients: SELECT })
    }

    sanityPages(slug: { current: { eq: "ingredienser" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }
  }
`
