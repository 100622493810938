export function ExtractAllIngredients(rawIngredients) {
  const ingredients = []

  rawIngredients.forEach(ingredient => {
    var divideIngrendinets = ingredient.split(" ")
    const newIngredient = divideIngrendinets[
      divideIngrendinets.length - 1
    ].replaceAll(".", "")

    var format = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/
    //    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

    if (!newIngredient.match(format) && newIngredient.length > 1)
      ingredients.push(newIngredient.toLowerCase())
  })

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  return ingredients.filter(onlyUnique).sort()
}
